<template>
  <div
    :class="[
      'container-' + organism.containerWidth,
      'organism tabbed-content',
      organism.hideOrganism ? 'hidden' : '',
    ]"
  >
    <header class="pt-10">
      <div class="px-8 mx-auto text-center text-white header-content">
        <div v-if="organism.collectionIcons" class="collections">
          <AtomsCollectionButton
            v-for="icon in organism.collectionIcons"
            :collection="icon"
            class="mr-2"
          />
        </div>
        <h2
          v-if="organism.title1"
          class="title1 mb-3 leading-none font-figtree text-3xl sm:text-[44px] font-semibold"
        >
          {{ organism.title1 }}
        </h2>
        <h3
          v-if="organism.title2"
          class="font-medium uppercase mb-9 title2 text-bc-bright-blue font-figtree text-[19px]"
        >
          {{ organism.title2 }}
        </h3>
        <div
          class="description"
          v-if="organism.description"
          v-html="organism.description"
        ></div>
      </div>
      <MoleculesTabbedContentTabs
        v-if="organism.item"
        :organism="organism.item"
        :default-tab-hash="organism.defaultTabHash"
        :target-tab-hash="organism.targetTabHash"
        :variant="organism.variant"
      >
        <template v-for="item in organism.item" #[item.id]>
          <slot :name="item.id"></slot>
        </template>
      </MoleculesTabbedContentTabs>
    </header>
  </div>
</template>

<script setup>
const props = defineProps({
  organism: Object,
});

onMounted(() => {
  if (props.organism.containerWidth === "full-width") {
    addFullWidthSupport();
  }
});
</script>
